import { RouteQuery } from '@swe/shared/providers/router/constants';
import { isSSR } from '@swe/shared/utils/environment';
import { uuidv4 } from '@swe/shared/utils/uuid';

import { useMemo } from 'react';

import { CarouselOrigin } from 'common/entities/product-analitycs';
import { Routes } from 'common/router/constants';
import { PlatformOs } from 'common/use-cases/use-platform-os';
import { Cart } from 'entities/cart/cart';
import { CarouselRuleType } from 'entities/product/carousel';
import { ShopInfo } from 'entities/shop/info';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

enum AEventType {
  APP_INIT = 'init',
  PAGE_VIEW = 'page_view',
  SIGN_IN = 'sign_in',
  SIGN_OUT = 'sign_out',
  SIGN_UP_START = 'sign_up_start',
  SIGN_UP_END = 'sign_up_end',
  PRODUCT_DETAILS_VIEW = 'product_details_view',
  CART_CHANGE = 'cart_change',
  CART_CHECKOUT = 'cart_checkout',
  ORDER_PLACE = 'order_place',
  PWA_INSTALLED = 'pwa_installed',
  SIGN_UP_POPUP_CLICKED = 'signup_popup_opened',
  ENGAGEMENT_TIME = 'engagement_time',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  PROMO_LOADED_FROM_BACKEND = 'promo_loaded_from_backend',
  PROMO_DISPLAYED_IN_CART = 'promo_displayed_in_cart',
  ORDER_FIRST = 'order_first',
  BANNER_CLICK = 'banner_click',
  SEARCH = 'search',
  CAROUSEL_VIEW = 'carousel_view',
  CAROUSEL_IMPRESSION = 'carousel_impression',
}

type CarouselItem = {
  carouselId: string | number;
  carouselName?: string;
  carouselOrigin: CarouselOrigin;
  carouselType?: CarouselRuleType;
};

type EcomItem = {
  itemId: ProductId;
  itemName: string;
  coupon: string; // item-level coupon code
  discount: number | string; // item-level discount
  price: number; // item price after discount
  index: number; // index of item in list
  itemBrand: string;
  itemCategory: string;
  itemCategory2: string;
  itemListId: string; // from where the item was viewed promotion_{id} | carusel_{id} | catalog
  itemListName: string; // from where the item was viewed name of the promotion/carusel/empty
  itemVariant: string;
  quantity: number;
} & Partial<CarouselItem>;

type Promos = {
  promo_ids: number[];
  segments: number[];
};

type Search = {
  search_term: string;
  client_fingerprint?: EntityID<string>;
};

type AEventPayloadByType = {
  [AEventType.APP_INIT]: undefined;
  [AEventType.PAGE_VIEW]: any;
  [AEventType.SIGN_IN]: undefined;
  [AEventType.SIGN_OUT]: undefined;
  [AEventType.SIGN_UP_START]: undefined;
  [AEventType.SIGN_UP_END]: undefined;
  [AEventType.PRODUCT_DETAILS_VIEW]:
    | { product_id: EntityID; price: number; oldPrice?: number; basePrice: number }
    | { product_group_id: EntityID };
  [AEventType.CART_CHANGE]: { items: { qty: number; variantId: EntityID }[] };
  [AEventType.CART_CHECKOUT]: undefined;
  [AEventType.ORDER_PLACE]: Cart;
  [AEventType.PWA_INSTALLED]: undefined;
  [AEventType.SIGN_UP_POPUP_CLICKED]: undefined;
  [AEventType.ENGAGEMENT_TIME]: { duration: number };
  [AEventType.VIEW_ITEM]: EcomItem;
  [AEventType.ADD_TO_CART]: EcomItem[];
  [AEventType.REMOVE_FROM_CART]: EcomItem[];
  [AEventType.VIEW_CART]: EcomItem[];
  [AEventType.BEGIN_CHECKOUT]: EcomItem[];
  [AEventType.PROMO_LOADED_FROM_BACKEND]: Promos;
  [AEventType.PROMO_DISPLAYED_IN_CART]: Promos;
  [AEventType.ORDER_FIRST]: number;
  [AEventType.BANNER_CLICK]: {
    banner: {
      banner_id: string;
      position: number;
      creative_id: string;
      account_id: number;
      banner_link: string;
    };
    carousel: {
      num_banners: number;
    };
    page: {
      url: string;
      referrer: string;
    };
  };
  [AEventType.SEARCH]: Search;
  [AEventType.CAROUSEL_VIEW]: CarouselItem;
  [AEventType.CAROUSEL_IMPRESSION]: CarouselItem & {
    topProducts: string[];
  };
};

type Device = {
  id: EntityID<string>;
  type: 'desktop' | 'mobile' | 'tablet';
};

type AEventMeta = {
  session: Session;
  device: Device;
  timestamp: Date;
  route: { pathname: Routes; query: RouteQuery };
  user?: {
    id: EntityID;
  };
  store: Pick<ShopInfo, 'location' | 'id' | 'name'>;
  platformOs: PlatformOs;
};

type PushEvent = <ET extends AEventType>(type: ET, payload: AEventPayloadByType[ET]) => void;

type PushEventWithMeta = <ET extends AEventType>(type: ET, payload: AEventPayloadByType[ET], meta: AEventMeta) => void;

type AnalyticsAdapter = {
  pushEvent: PushEventWithMeta;
};

const SESSION_KEY = '__sw-session';
type Session = {
  id: EntityID<string>;
  createdAt: DateISOString;
};
const initSession = () => {
  if (isSSR) {
    return { id: '0', createdAt: '0' };
  }
  const storedSessionString = sessionStorage.getItem(SESSION_KEY);
  try {
    if (storedSessionString) {
      return JSON.parse(storedSessionString) as Session;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  const newSession: Session = {
    id: uuidv4(),
    createdAt: new Date().toISOString(),
  };
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(newSession));
  return newSession;
};
const useSession = (): Session => {
  return useMemo<Session>(initSession, []);
};

const DEVICE_ID_KEY = '__sw-device-id';
const getDeviceId = () => {
  if (isSSR) {
    return '0';
  }
  const storeDeviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (storeDeviceId) {
    return storeDeviceId;
  }
  const newDeviceId = uuidv4();
  localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
  return newDeviceId;
};
const useDeviceId = () => {
  return useMemo(getDeviceId, [getDeviceId]);
};

export type { Session, AEventPayloadByType, PushEvent, PushEventWithMeta, AnalyticsAdapter, AEventMeta, EcomItem };
export { AEventType, useDeviceId, useSession };
