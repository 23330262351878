import hashSum from 'hash-sum';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import hash from 'object-hash';

import { Serializable } from '@swe/shared/types/json';
import { InvertKeyValue } from '@swe/shared/types/utility/invert.type';

const traverseLeaves = (obj: Record<string, any>, cb: (val: any, path: string[]) => void, path: string[] = []) => {
  forIn(obj, (val, key) => {
    if (isObject(val)) {
      traverseLeaves(val, cb, [...path, key]);
    } else {
      cb(val, [...path, key]);
    }
  });
};

const flat = (obj: Record<string, any>, separator = '.'): Record<string, any> =>
  Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== 'object' || !obj[key]) {
      return {
        ...acc,
        [key]: obj[key],
      };
    }

    const flattenedChild = flat(obj[key], separator);

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({ ...childAcc, [`${key}${separator}${childKey}`]: flattenedChild[childKey] }),
        {},
      ),
    };
  }, {});

const prefixKeys = (obj: Record<string, any>, prefix = ''): Record<string, any> =>
  mapKeys(obj, (_, key) => `${prefix}${key}`);

const pickByKey = (obj: Record<string, any>, predicate: (key: string) => boolean) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => predicate(key)));

const invert = <T extends Record<PropertyKey, PropertyKey>>(obj: T) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k])) as InvertKeyValue<T>;

const jsonParseSafe = <T extends Serializable = Serializable>(val: any) => {
  if (val === null || val === undefined || val === '') {
    return null;
  }
  try {
    return JSON.parse(val) as T;
  } catch (e) {
    return null;
  }
};

export {
  has,
  merge,
  traverseLeaves,
  isEqual,
  hash,
  get,
  pick,
  omit,
  flat,
  prefixKeys,
  cloneDeep,
  defaults,
  omitBy,
  isObject,
  mergeWith,
  mapValues,
  hashSum,
  pickBy,
  pickByKey,
  invert,
  jsonParseSafe,
  mapKeys,
};
