import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ShopInfo } from 'entities/shop/info';

type Data = ShopInfo;
type Params = undefined;

const NAME = '/Store/GetStoreInfo';
const GetShopInfoEndpoint = createModernEndpoint<Params, Data>(
  NAME,
  { throwOnEmptyResponse: true },
  {
    dedupingInterval: 10 * 60 * 1000,
  },
);

export default GetShopInfoEndpoint;
export { GetShopInfoEndpoint };
