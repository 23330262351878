import Price from '@swe/shared/ui-kit/components/price';

import Text from '@swe/shared/ui-kit/components/text';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

import { getProductVariantTierPriceRanges, ProductVariant } from 'entities/product/product';

type ProductTierPricingTableProps = {
  variant: ProductVariant;
} & ComponentHasClassName;

const ProductTierPricingTable = ({ variant, className }: ProductTierPricingTableProps) => {
  const tierRanges = getProductVariantTierPriceRanges(variant);

  return (
    <div className={className}>
      {tierRanges.map(({ save, price, basePrice, range }, key) => {
        let rangeText = '';
        if (range[1] === range[0]) {
          rangeText = `${range[0]}`;
        } else {
          rangeText = `${range[0]} ${Number.isFinite(range[1]) ? `- ${range[1]}` : 'and more'}`;
        }

        return (
          <div
            key={key}
            className={styles.row}
          >
            <Text
              variant="body"
              size="xl"
            >
              {rangeText}
              {save ? (
                <span>
                  &nbsp;(save <Price value={save} />)
                </span>
              ) : null}
            </Text>
            <Text
              variant="body"
              size="xl"
              className={styles.prices}
            >
              <Price
                old
                value={basePrice}
              />
              <Price
                className={styles.price}
                value={price}
              />
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export type { ProductTierPricingTableProps };
export { ProductTierPricingTable };
export default ProductTierPricingTable;
