import { useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import {
  Factory,
  Endpoint,
  EndpointFactory,
  EndpointRequest,
  EndpointUseRequest,
  FetchFn,
  EndpointUseRequestConfig,
  EndpointPreload,
  EndpointKey,
  EndpoingUseRequestReturn,
  BoundedMutator,
} from './transport.types';

const DEFAULT_QUERY_CONFIG: EndpointUseRequestConfig = { dedupingInterval: 10 * 1000 };

const createEndpointFactory: Factory =
  <FetchConfig, EndpointURL = string>(
    _fetchFn: FetchFn<FetchConfig, EndpointURL>,
  ): EndpointFactory<FetchConfig, EndpointURL> =>
  <Params, Data, Error>(
    endpointUrl: EndpointURL,
    defaultFetchConfig: FetchConfig = {} as FetchConfig,
    endpointQueryConfig: EndpointUseRequestConfig = {},
  ) => {
    const buildKey: EndpointKey<Params> = (params) =>
      params ? [endpointUrl as string, params] : [endpointUrl as string];

    const _request = async (params: Params, fetchConfig?: FetchConfig) => {
      const fetchFn: FetchFn<FetchConfig, EndpointURL, Params, Data> = _fetchFn;
      const config: FetchConfig = {
        ...defaultFetchConfig,
        ...fetchConfig,
      };

      return fetchFn(endpointUrl, params ?? ({} as Params), config).then(([data]) => data);
    };

    const useRequest = (
      params: Params,
      _fetchConfig: FetchConfig,
      _queryConfig: EndpointUseRequestConfig,
    ): EndpoingUseRequestReturn<Data> => {
      const queryConfig: EndpointUseRequestConfig = {
        ...DEFAULT_QUERY_CONFIG,
        ...endpointQueryConfig,
        ..._queryConfig,
      };

      const queryKey = buildKey(params);
      const queryClient = useQueryClient();
      const fetchConfig: FetchConfig = {
        ...defaultFetchConfig,
        ..._fetchConfig,
      };

      const query = useQuery<Data, Error>({
        queryKey,
        queryFn: () =>
          queryConfig.use ? queryConfig.use(() => _request(params, fetchConfig)) : _request(params, fetchConfig),
        enabled: params !== null,
        refetchInterval: queryConfig.refreshInterval,
        staleTime: queryConfig.dedupingInterval,
        placeholderData: queryConfig.keepPreviousData ? keepPreviousData : undefined,
        retry: queryConfig.retry,
      });

      const { refetch } = query;
      const mutate = useCallback<BoundedMutator<Data>>(
        async (data, shouldRevalidate = true) => {
          const queryKey = buildKey(params);
          const currentData = await queryClient.getQueryData(queryKey);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          let newData: Data = typeof data === 'function' ? await data(currentData) : data;
          if (newData !== undefined) {
            queryClient.setQueryData(queryKey, newData);
          }
          if (shouldRevalidate) {
            newData = (await refetch()).data as Data;
          }
          return new Promise<any>((resolve) => {
            setTimeout(() => resolve(newData), 0);
          });
        },
        [queryClient, params, refetch],
      );

      return useMemo(
        () => ({
          data: query.data ?? undefined,
          error: query.error,
          isValidating: query.isLoading,
          isLoading: query.isLoading,
          isRevalidating: query.isRefetching,
          mutate,
        }),
        [mutate, query.data, query.error, query.isLoading, query.isRefetching],
      );
    };

    const request: EndpointRequest<FetchConfig, Params, Data> = async (params, fetchConfig) => {
      return _request(params, fetchConfig);
    };

    const preload: EndpointPreload<FetchConfig, Params, Data> = async (params, fetchConfig, queryClient) => {
      const queryKey = buildKey(params);
      return queryClient.ensureQueryData({
        queryKey,
        queryFn: () => _request(params, fetchConfig),
      });
    };

    const endpoint: Endpoint<FetchConfig, Params, Data, Error> = {
      useRequest: useRequest as EndpointUseRequest<FetchConfig, Params, Data, Error>,
      request,
      preload,
      key: buildKey,
    };

    return endpoint;
  };

export { createEndpointFactory, DEFAULT_QUERY_CONFIG };
