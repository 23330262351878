import { IncomingMessage } from 'http';

import { isOurKey } from '@swe/shared/providers/persist-state/constants';
import CookieStorage from '@swe/shared/providers/persist-state/cookie-storage';
import { RouteQuery } from '@swe/shared/providers/router/constants';

import { getCookie, getCookies } from '@swe/shared/tools/cookie';
import { Theme } from '@swe/shared/ui-kit/theme/provider/themes';
import { defaults, omitBy, pickByKey } from '@swe/shared/utils/object';

import { AppRuntimeConfig } from '@swe/shop-ui/runtime-config';
import { loadTheme } from 'app/theme';

import { AppSettings } from 'app/types';
import { getClientWidthFallback } from 'app/utils';
import { createStoreIdBasePath, getStoreIdFromBasePath } from 'common/router/utils';
import { getSaleType, SALE_TYPE_STORAGE_KEY } from 'common/use-cases/use-sale-type';
import GetShopConfigEndpoint from 'endpoints/shop/get-shop-config';

import { mapShopConfigDTOToEntity, SHOP_CONFIG_DEFAULTS, ShopConfig, ShopConfigDTO } from 'entities/shop/config';
import { StoreSaleType } from 'entities/shop/sale-type';

const BASE_PATH_PATTERNS = [
  /^\/(locations\/[\d\-A-Za-z]+\/((medical|recreational)-)?menu)/,
  /^\/(locations\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+\/shop)/,
  /^\/(stores\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+\/shop)/,
  /^\/(stores\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/,
  /^\/(shop\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/,
  /^\/(shop\/[\d\-A-Za-z]+)/,
];

const CURALEAF_BASE_PATH_PATTERNS = [/^\/(shop\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/];
const SUPERGOOD_BASE_PATH_PATTERNS = [/^\/(stores\/[\d\-A-Za-z]+)/];
const GRASSHOPER_BASE_PATH_PATTERNS = [/^\/(shop\/[\d\-A-Za-z]+)/];

const HOST_TO_BASE_PATH_PATTERNS: Record<string, RegExp[]> = {
  'supergoodstore.com': SUPERGOOD_BASE_PATH_PATTERNS,
  'curaleaf.com': CURALEAF_BASE_PATH_PATTERNS,
  'grasshopperclub.com': GRASSHOPER_BASE_PATH_PATTERNS,
};

const getBasePathPatternsForHost = (host: string) => HOST_TO_BASE_PATH_PATTERNS[host] ?? BASE_PATH_PATTERNS;

const DEVICE_TYPE_HEADER = 'CF-Device-Type';

const isRedirect = (error: any): error is Response => {
  return error instanceof Response && 'status' in error && [301, 302, 303, 307, 308].includes(error.status);
};

const isStoreNotFound = (error: any) => {
  return (
    error && typeof error === 'object' && 'message' in error && error.message === 'Store is not found by given domain'
  );
};

const isQueryCallAborted = (error: any) => {
  return error && typeof error === 'object' && 'message' in error && error.message.includes('query() call aborted:');
};

const resolveStore = async (url: URL): Promise<ShopConfig> => {
  const { pathname, host, search } = url;
  let basePath = pathname.split('/')[1] ?? '';
  const basePathPatterns = getBasePathPatternsForHost(host);
  for (let i = 0; i < basePathPatterns.length; i++) {
    const [, customBasePath] = pathname.match(basePathPatterns[i]) ?? [];
    if (customBasePath) {
      basePath = customBasePath;
      break;
    }
  }

  const storeIdFromBasePath = getStoreIdFromBasePath(basePath);

  const config = await GetShopConfigEndpoint.request(storeIdFromBasePath ? {} : { domain: host, routeName: basePath }, {
    headers: { StoreId: (storeIdFromBasePath || -1).toString() },
  });

  const { routeName } = config;

  if (!storeIdFromBasePath && basePath !== routeName) {
    throw new Response('', { status: 302, headers: { Location: `/${routeName}${pathname}${search}` } });
  }

  return {
    ...defaults(
      omitBy(mapShopConfigDTOToEntity(config as ShopConfigDTO), (val) => val === null),
      SHOP_CONFIG_DEFAULTS,
    ),
    routeName: `/${storeIdFromBasePath ? createStoreIdBasePath(storeIdFromBasePath) : routeName}`,
    originalRouteName: `/${routeName}`,
  } as ShopConfig;
};

const getSaleTypeFromReq = (medicalMenuEnabled: boolean, cookie?: string | Record<string, string | undefined>) => {
  const storagesFallback = {
    cookies: pickByKey(getCookies({ cookie }), isOurKey),
  };

  return getSaleType(
    medicalMenuEnabled,
    new CookieStorage(storagesFallback.cookies).getItem<Exclude<StoreSaleType, undefined>>(SALE_TYPE_STORAGE_KEY),
  );
};

const prepareAppSettings = async ({
  reqHeaders,
  query = {},
  storeConfig,
  runtimeConfig,
}: {
  reqHeaders: IncomingMessage['headers'];
  query?: RouteQuery;
  storeConfig: ShopConfig;
  runtimeConfig: AppRuntimeConfig;
}): Promise<AppSettings> => {
  const { assetPrefix } = runtimeConfig;
  const reqUserAgent = reqHeaders['user-agent'];
  const deviceTypeHeader = reqHeaders[DEVICE_TYPE_HEADER];
  const deviceType = Array.isArray(deviceTypeHeader) ? deviceTypeHeader[0] : deviceTypeHeader;
  const clientWidthFallback = getClientWidthFallback({ userAgent: reqUserAgent, deviceType });

  const { theme } = storeConfig;

  const storagesFallback = {
    cookies: pickByKey(getCookies({ cookie: reqHeaders.cookie }), isOurKey),
  };

  const reqHasAuthCookie = !!getCookie('UserLoginCookie', { cookie: reqHeaders.cookie });

  const themeName = (query['__sw-theme'] as Theme) ?? theme;
  const themeBuild = await loadTheme(themeName, assetPrefix);

  return {
    clientWidthFallback,
    reqHasAuthCookie,
    themeBuild,
    storagesFallback,
    reqUserAgent,
    runtimeConfig,
    storeConfig,
  };
};

export { prepareAppSettings, getSaleTypeFromReq, resolveStore, isQueryCallAborted, isRedirect, isStoreNotFound };
