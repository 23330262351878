import { ApiErrorCode } from '@swe/shared/network/endpoint-factories/modern/types';
import { useLayout } from '@swe/shared/providers/layout';
import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import Grid from '@swe/shared/ui-kit/components/grid';
import { ChevronLeftIcon } from '@swe/shared/ui-kit/components/icon';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';
import Stack from '@swe/shared/ui-kit/components/stack';

import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';
import { parseDateOnlyISO } from '@swe/shared/utils/date';

import { useCallback, useEffect, useMemo, useRef } from 'react';

import { FixedFooterBox } from 'common/components/fixed-footer-box';
import { PathBasedBreadcrumbs, PathBasedBreadcrumbsProps } from 'common/components/path-based-breadcrumbs';
import { ProductVariantSkeletonized } from 'common/components/variants';
import { AdditionalProductsCarousel } from 'common/containers/additional-products-carousel';
import { DisableOrdering } from 'common/containers/disable-ordering';
import { ProductNotFound } from 'common/containers/product-not-found';
import { Reminder } from 'common/containers/reminder';
import { productAnalyticsMap } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useCart, useCartState } from 'common/providers/cart';
import { useRouterQuery } from 'common/router';
import { getBreadcrumbTitle, Routes } from 'common/router/constants';
import { buildNamedId } from 'common/router/utils';
import { useGoPrevious } from 'common/use-cases/use-go-previous';
import { usePDP } from 'common/use-cases/use-pdp';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { AddProductSkeletonized } from 'domains/product/components/product-details/components/add-to-cart';
import { ProductAttributesSkeletonized } from 'domains/product/components/product-details/components/attributes';
import { ProductDescriptionSkeletonized } from 'domains/product/components/product-details/components/description';
import { ProductDiscountSkeletonized } from 'domains/product/components/product-details/components/discount';
import { ProductImageCarousel as ImageCarousel } from 'domains/product/components/product-details/components/image';
import { QuantitySuggestionsSkeletonized } from 'domains/product/components/product-details/components/quantity-suggestions';
import { ProductTierPricingSkeletonized } from 'domains/product/components/product-details/components/tier-pricing';
import { ProductTitleSkeletonized } from 'domains/product/components/product-details/components/title';
import { LaboratoryData } from 'domains/product/containers/laboratory-data';
import styles from 'domains/product/containers/product-details/styles.module.scss';
import { useLabData } from 'domains/product/use-cases/use-lab-data';
import useProductById, { useProductByVariant } from 'domains/product/use-cases/use-product';
import { GetProductError } from 'endpoints/product/get-product-by-variant-id';
import {
  getProductNameWithVariant,
  getProductVariantPrice,
  hasOnePromoAtLeast,
  Product,
  ProductVariant,
} from 'entities/product/product';

type ProductDetailsProps = (
  | {
      productId: Product['id'];
      variantId?: ProductVariant['id'];
      product?: undefined;
      variant?: undefined;
      setVariant?: undefined;
      error?: GetProductError;
    }
  | {
      productId?: undefined;
      variantId: ProductVariant['id'];
      product?: undefined;
      variant?: undefined;
      setVariant?: undefined;
      error?: GetProductError;
    }
  | {
      productId?: undefined;
      variantId?: undefined;
      product?: Product;
      variant?: ProductVariant;
      setVariant: (variant: ProductVariant) => void;
      error?: GetProductError;
    }
) & {
  shallowVariantId?: ProductVariant['id'];
  isInModal?: boolean;
  canGoBack?: boolean;
  onProductAdd?: () => void;
  onClose?: () => void;
  setHeaderOffsetBlock?: any;
  onUpdateReminder?: () => void;
};

const ProductDetails = ({
  productId,
  variantId,
  shallowVariantId,
  isInModal,
  canGoBack,
  onProductAdd,
  onClose,
  setHeaderOffsetBlock,
  onUpdateReminder,
  ...pdpOuter
}: ProductDetailsProps) => {
  const { buildCatalogLink } = useCatalogQuery();
  const productById = useProductById(productId, variantId);
  const { mutate, ...productByVariantId } = useProductByVariant(variantId);
  const productFromRequest = productId ? productById : variantId ? productByVariantId : undefined;
  const labDataRef = useRef<HTMLDivElement>(null);
  const { product, variant: _variant, setVariant, error } = productFromRequest ?? pdpOuter;
  const {
    labData,
    isLoading: isLabDataLoading,
    mutate: revalidateLabData,
    error: labDataError,
  } = useLabData(_variant?.id, _variant?.detailedLabDataExists);
  const { mobile, moreThan, lessThan } = useBreakpoint();
  const { headerRect } = useLayout();
  const goPrevious = useGoPrevious({
    defaultRoute: buildCatalogLink({
      filters: null,
      subPath: null,
      searchTerm: null,
      page: null,
    }),
    useBackHistory: true,
  });
  const variant: ProductVariant | undefined = useMemo(
    () =>
      _variant
        ? {
            ..._variant,
            promos: (_variant?.promos || []).map((promo) => ({
              ...promo,
              ...(promo.endDate
                ? {
                    endDate: parseDateOnlyISO(`${promo.endDate}`.split('T')[0]),
                  }
                : {}),
            })),
          }
        : _variant,
    [_variant],
  );
  const { scroll } = useNearestScrollable();

  const scrollToLabData = useCallback(() => {
    const adjustment = isInModal ? (mobile ? 16 : 100) : headerRect.height;
    const offset = labDataRef.current?.offsetTop ? labDataRef.current.offsetTop - adjustment : 0;
    scroll({ top: offset, behavior: 'smooth' });
  }, [headerRect.height, isInModal, mobile, scroll]);

  const { pushEvent } = useAnalytics();
  // TODO: fix the bug when variant becomes undefined on variant change and then delete this
  const lastVariantIdSent = useRef<string | number | undefined>();
  useEffect(() => {
    if (variant && variant.id !== lastVariantIdSent.current) {
      lastVariantIdSent.current = variant.id;
      const [price, oldPrice, basePrice] = getProductVariantPrice(variant, 1);
      pushEvent(AEventType.PRODUCT_DETAILS_VIEW, { product_id: variant.id, price, oldPrice, basePrice });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushEvent, variant?.id]);

  const cart = useCart();
  const { isLoading } = useCartState();
  const { analyticsData } = usePDP();
  const lastVariantIdSentGTM = useRef<string | number | undefined>();
  const query = useRouterQuery();

  useEffect(() => {
    if (variant && product && !isLoading && lastVariantIdSentGTM.current !== variant?.id) {
      lastVariantIdSentGTM.current = variant?.id;
      pushEvent(
        AEventType.VIEW_ITEM,
        productAnalyticsMap({
          product,
          cart,
          analyticalItemListName: query.originName ? `${query.originName}` : analyticsData?.originName,
          analyticalItemListId: query.originId ? `${query.originId}` : analyticsData?.originId,
          index: query.idx ? parseInt(`${query.idx}`, 10) : analyticsData?.idx || 0,
          variantId: variant?.id,
          analyticalItemCarousel: analyticsData?.carousel,
        }),
      );
    }
  }, [
    analyticsData?.idx,
    analyticsData?.originId,
    analyticsData?.originName,
    analyticsData?.carousel,
    cart,
    isInModal,
    isLoading,
    product,
    pushEvent,
    query.idx,
    query.originId,
    query.originName,
    variant,
  ]);

  const breadcrumbs = useMemo(
    () =>
      [
        {
          text: getBreadcrumbTitle(Routes.Catalog),
          href: buildCatalogLink({ filters: null, subPath: null, searchTerm: null, page: null }),
        },
        product?.category
          ? {
              text: product.category.name,
              href: buildCatalogLink({
                filters: null,
                subPath: [buildNamedId(product.category.name, product.category.tagId)],
                searchTerm: null,
                page: null,
              }),
            }
          : { text: 'Loading...' },
        product && variant && { text: getProductNameWithVariant(product, variant) },
      ].filter(Boolean) as PathBasedBreadcrumbsProps['breadcrumbs'],
    [buildCatalogLink, product, variant],
  );

  if (error?.code === ApiErrorCode.NotFound) {
    return <ProductNotFound />;
  }

  const ProductImageCarousel = (
    <ImageCarousel
      product={product}
      variant={variant}
    />
  );

  const ProductImageBlock = !isInModal ? (
    <Stack spacing="sm">
      <Button
        className={styles.backBtn}
        color="light"
        ariaLabel="Back"
        icon={ChevronLeftIcon}
        onClick={goPrevious}
      >
        Back
      </Button>
      {ProductImageCarousel}
    </Stack>
  ) : (
    ProductImageCarousel
  );

  const ProductTitle = (
    <ProductTitleSkeletonized
      product={product}
      variant={variant}
    />
  );

  const ProductInfoBlock = (
    <>
      <ProductAttributesSkeletonized
        product={product}
        variant={variant}
        labData={labData}
        isLabDataLoading={isLabDataLoading}
        onShowMoreLabData={scrollToLabData}
      />

      <ProductDescriptionSkeletonized product={product} />
    </>
  );

  const carouselVariant = shallowVariantId ?? variant?.id;
  const Carousel = (
    <AdditionalProductsCarousel
      key={carouselVariant}
      variantIds={carouselVariant ? [carouselVariant] : undefined}
      extendToView={isInModal ? 'modal' : true}
      origin="pdp"
    />
  );

  const reminder = (
    <DisableOrdering>
      <Reminder
        variant={variant}
        onUpdate={variantId ? mutate : onUpdateReminder}
      >
        <AddProductSkeletonized
          ref={setHeaderOffsetBlock}
          product={product}
          variant={variant}
          onProductAdd={onProductAdd}
        />
      </Reminder>
    </DisableOrdering>
  );

  const AddToCart = mobile ? (
    <Portal name="fixedFooter">
      <FixedFooterBox>{reminder}</FixedFooterBox>
    </Portal>
  ) : (
    reminder
  );

  const ProductPriceAndVariants = (
    <>
      <ProductVariantSkeletonized
        product={product}
        selected={variant?.id}
        onSelect={setVariant!}
      />

      {variant?.qtyPresets && variant.qtyPresets.length > 0 && (
        <QuantitySuggestionsSkeletonized
          product={product}
          variant={variant}
          presets={variant.qtyPresets}
        />
      )}

      {isInModal ? !mobile && AddToCart : AddToCart}

      {product && hasOnePromoAtLeast(product) && (
        <div className={styles.discountDisclaimer}>Discount will apply in the cart if all promo conditions are met</div>
      )}

      <ProductTierPricingSkeletonized
        product={product}
        variant={variant}
      />
      <ProductDiscountSkeletonized variant={variant} />
    </>
  );
  const headerOffset = headerRect.height + 24;
  const stickyElementProps =
    moreThan.sm && !isInModal ? { className: styles.element_sticky, style: { top: headerOffset } } : {};

  const LabData = variant?.detailedLabDataExists ? (
    <LaboratoryData
      ref={labDataRef}
      className={styles.labData}
      labData={labData}
      error={!!labDataError}
      onRevalidate={revalidateLabData}
      isLoading={isLabDataLoading}
    />
  ) : null;

  if (lessThan.xl) {
    return (
      <PatternThemeProvider name="pdp">
        <Stack spacing={{ xs: 'sm', sm: 'md', md: 'lg' }}>
          {!isInModal && <PathBasedBreadcrumbs breadcrumbs={breadcrumbs} />}
          <Grid.Row
            spacing={{ xs: 'sm', sm: 'lg', lg: 'xxl' }}
            vAlign="start"
            wrap
          >
            <Grid.Cell
              {...stickyElementProps}
              cols={{ xs: 12, md: 6 }}
            >
              {ProductImageBlock}
            </Grid.Cell>
            <Grid.Cell cols={{ xs: 12, md: 6 }}>
              <Stack spacing={{ xs: 'md', md: 'lg' }}>
                {ProductTitle}
                <Stack spacing={{ xs: 'xs', md: 'lg' }}>
                  {ProductPriceAndVariants}
                  <Stack spacing={{ md: 'xs' }}>{ProductInfoBlock}</Stack>
                </Stack>
              </Stack>
            </Grid.Cell>
          </Grid.Row>
          {Carousel}
          {LabData}
        </Stack>
      </PatternThemeProvider>
    );
  }

  return (
    <PatternThemeProvider name="pdp">
      <Stack spacing="lg">
        {!isInModal && <PathBasedBreadcrumbs breadcrumbs={breadcrumbs} />}
        <Stack spacing={{ sm: 'xxl' }}>
          <Grid.Row
            spacing={{ xs: 'lg' }}
            vAlign="start"
          >
            <Grid.Cell
              {...stickyElementProps}
              cols={{ xs: 12, md: 4 }}
            >
              {ProductImageBlock}
            </Grid.Cell>
            <Grid.Cell cols={{ xs: 12, md: 8, lg: 4 }}>
              <Stack spacing={{ xs: 'lg' }}>
                {ProductTitle}
                <Stack spacing={{ xs: 'xs' }}>{ProductInfoBlock}</Stack>
              </Stack>
            </Grid.Cell>
            <Grid.Cell
              {...stickyElementProps}
              cols={{ xs: 12, md: 8, lg: 4 }}
            >
              <Stack spacing={{ md: 'md' }}>{ProductPriceAndVariants}</Stack>
            </Grid.Cell>
          </Grid.Row>
          {Carousel}
          {LabData}
        </Stack>
      </Stack>
    </PatternThemeProvider>
  );
};

export type { ProductDetailsProps };
export { ProductDetails };
