import { v4 as uuidv4 } from 'uuid';

const customUID = (onlyChars = false): string => {
  let result = uuidv4();
  if (onlyChars) {
    while (/[0-9]{1,}/.test(result)) {
      result = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '');
    }
  }
  return result;
};

export { uuidv4, customUID };
