import { useCallback, useEffect, useState } from 'react';

import { isSSR } from '@swe/shared/utils/environment';

const getIsPWA = () => (isSSR ? false : window.matchMedia('(display-mode: standalone)').matches);

const useIsPWA = () => {
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

  const onChange = useCallback((evt: { matches: boolean }) => {
    setIsInStandaloneMode(evt.matches);
  }, []);

  useEffect(() => {
    setIsInStandaloneMode(
      window.matchMedia('(display-mode: standalone)').matches ||
        'standalone' in window.navigator ||
        document.referrer.includes('android-app://'),
    );

    const matchMedia = window.matchMedia('(display-mode: standalone)');

    matchMedia.addEventListener('change', onChange);

    return () => {
      matchMedia.removeEventListener('change', onChange);
    };
  }, [onChange]);

  return isInStandaloneMode;
};

export { useIsPWA, getIsPWA };
