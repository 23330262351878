import { isMatchRouter } from '@swe/shared/providers/router/helpers';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Container, ContainerProps } from '@swe/shared/ui-kit/components/container';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { PORTAL_TARGET } from '@swe/shared/ui-kit/components/portal';
import { isSSR } from '@swe/shared/utils/environment';
import cn from 'clsx';

import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import { FOOTER_ID, LinkToContent, MAIN_CONTENT_ID } from 'common/components/link-to-content';

import { useRouterPathname } from 'common/router';
import { Routes } from 'common/router/constants';

export type MainLayoutProps = {
  preHeader?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  subheader?: ReactNode;
  children?: ReactNode;
  size?: ContainerProps['size'];
  centered?: true | 'x' | 'y';
  paddings?: 'none' | 'both' | 'vertical' | 'horizontal';
  preFooter?: ReactNode;
};

const getCenterClassName = (centered: MainLayoutProps['centered']) => {
  switch (centered) {
    case true:
      return styles._centered;
    case 'x':
      return styles._centered_x;
    case 'y':
      return styles._centered_y;
    default:
      return '';
  }
};

export const MainLayout = ({
  preHeader,
  header,
  children,
  centered,
  paddings = 'both',
  size,
  subheader,
  footer,
  preFooter,
}: MainLayoutProps) => {
  const { mobile } = useBreakpoint();

  const [repositionKey, setRepositionKey] = useState(0);

  const c = useCallback(() => {
    if (!mobile) return;
    setRepositionKey(repositionKey + 1);
  }, [mobile, repositionKey]);

  useEffect(() => {
    if (!isSSR && mobile) {
      window.addEventListener('resize', c);

      return () => {
        window.removeEventListener('resize', c);
      };
    }
  }, [c, mobile]);

  const pathname = useRouterPathname();

  const calcPageLocatorName = useMemo(() => {
    const obj = Object.entries(Routes).reduce<Record<string, string>>(
      (acc, [name, route]) => ({ ...acc, [route]: name }),
      {},
    );
    if (obj[pathname]) {
      return obj[pathname];
    }

    const res = Object.values(Routes)
      .filter((route) => isMatchRouter(route, pathname))
      .sort((a, b) => (a[1].length > b[1].length ? -1 : 1));

    if (res.length) {
      return res[0][0];
    }

    return '';
  }, [pathname]);

  return (
    <Locator
      as="div"
      locatorType="page"
      locatorName={calcPageLocatorName}
      className={cn(styles.root, getCenterClassName(centered), paddings !== 'both' && styles[`_paddings_${paddings}`])}
    >
      <LinkToContent />
      <div className={styles.wrapper}>
        {preHeader && preHeader}
        {header && <header className={styles.header}>{header}</header>}
        {subheader && <div className={styles.subheader}>{subheader}</div>}
        <div className={styles.main}>
          <main
            className={styles.mainFill}
            id={MAIN_CONTENT_ID}
          >
            <Container
              className={styles.container}
              size={size}
            >
              <div className={styles.content}>{children}</div>
            </Container>
          </main>
          <div style={{ position: 'fixed', color: 'transparent', pointerEvents: 'none' }}>{repositionKey}</div>
          <div className={styles.fixedFooter}>
            <PORTAL_TARGET.FixedFooterTop />
            <PORTAL_TARGET.FixedFooter />
          </div>
        </div>
        {preFooter && <div className={styles.preFooter}>{preFooter}</div>}
        {footer && <footer id={FOOTER_ID}>{footer}</footer>}
      </div>
    </Locator>
  );
};
