import { Error500 } from '@swe/shared/ui-kit/components/error-500';

import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Cont404 } from '@swe/shop-ui/common/containers/404';
import logger from 'app/utils/logger';

const is404Error = (error: unknown) => isRouteErrorResponse(error) && error.status === 404;

const isChunkLoadingError = (error: any) =>
  error && typeof error === 'object' && 'name' in error && error.name.includes('ChunkLoadError');

const RootErrorPage = () => {
  return (
    <html lang="en">
      <body>
        <h1>Fatal client error</h1>
      </body>
    </html>
  );
};

const RootRouterErrorPage = () => {
  const error = useRouteError();
  useEffect(() => {
    logger.error(error, { level: 'fatal' });
  }, [error]);

  return <RootErrorPage />;
};

const RouterErrorPage = () => {
  const _error = useRouteError();
  const is404 = is404Error(_error);
  useEffect(() => {
    if (!is404) {
      logger.error(_error, { level: 'fatal' });
    }
  }, [_error, is404]);

  if (is404) {
    return <Cont404 />;
  }

  const error = isChunkLoadingError(_error) ? 'A new version of the page is available' : _error;

  return <Error500 error={error} />;
};

export { RootErrorPage, RootRouterErrorPage };
export default RouterErrorPage;
