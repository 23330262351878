import {
  QueryClient,
  QueryClientProvider,
  focusManager,
  hydrate,
  dehydrate,
  useQueryClient,
} from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMounted } from '@swe/shared/hooks/use-mounted';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';

type EndpointFactoryProviderProps = ComponentHasChildren & {
  revalidateOnInit?: boolean;
  externalApiHost?: string;
  externalApiBasePath?: string;
};

const context = createContext<{
  pauseRevalidateOnFocus: () => void;
  resumeRevalidateOnFocus: () => void;
  invalidateAll: () => Promise<void>;
}>(null!);

const QueryControlsProvider = context.Provider;
const useQueryControls = () => useContext(context);

const QueryProvider = ({
  children,
  revalidateOnInit = true,
  externalApiBasePath,
  externalApiHost,
}: EndpointFactoryProviderProps) => {
  useMemo(() => {
    if (!isSSR) {
      window.__sw_externalApiBasePath = externalApiBasePath;
      window.__sw_externalApiHost = externalApiHost;
    }
  }, [externalApiBasePath, externalApiHost]);

  const [revalidateOnFocus, setRevalidateOnFocus] = useState(true);
  const pauseRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(false), []);
  const resumeRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(true), []);

  const queryClient = useQueryClient();
  const invalidateAll = useCallback(() => {
    return queryClient.invalidateQueries({ refetchType: 'active' });
  }, [queryClient]);
  const wasInitiallyInvalidated = useRef(false);
  useMounted(() => {
    if (revalidateOnInit && !wasInitiallyInvalidated.current) {
      wasInitiallyInvalidated.current = true;
      void invalidateAll();
    }
  });

  useEffect(() => {
    focusManager.setFocused(revalidateOnFocus ? undefined : true);
  }, [revalidateOnFocus]);

  return (
    <QueryControlsProvider
      value={useMemo(
        () => ({ pauseRevalidateOnFocus, resumeRevalidateOnFocus, invalidateAll }),
        [pauseRevalidateOnFocus, resumeRevalidateOnFocus, invalidateAll],
      )}
    >
      {children}
    </QueryControlsProvider>
  );
};

export {
  useQueryControls,
  QueryProvider,
  QueryClientProvider,
  QueryClient,
  hydrate as hydrateQueryClient,
  dehydrate as dehydrateQueryClient,
};
export type { EndpointFactoryProviderProps };
export default QueryProvider;
