import { useMounted } from '@swe/shared/hooks/use-mounted';
import { GoogleApiProvider } from '@swe/shared/providers/google-api';
import { PersistStateStorageProvider } from '@swe/shared/providers/persist-state';
import SnackbarProvider from '@swe/shared/providers/snackbar';
import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';

import posthog from 'posthog-js';

import { PostHogProvider } from 'posthog-js/react';

import { useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { useAppSettings } from 'app/app-settings';

import { firebase } from 'app/firebase';
import { MainLayout } from 'app/layout/main';
import { usePageLayoutProps } from 'app/utils/page-meta';
import { ActionRequiredNotifier } from 'common/containers/action-required-notifier';
import AgeRestriction from 'common/containers/age-restriction';
import Footer from 'common/containers/footer';
import Header from 'common/containers/header';
import SubHeader from 'common/containers/header/containers/sub-header';
import SupHeader from 'common/containers/header/containers/sup-header';
import { MedicalCardAssistance } from 'common/containers/medical-card-assistance';
import { ProductDetailsModal } from 'common/containers/product-details-modal';
import { SignUpNotifier } from 'common/containers/sign-up-notifier';
import { SignupIncentivization } from 'common/containers/signup-incentivization';
import AnalyticsProvider from 'common/providers/analytics';
import { AnalyticsAdaptersConfig } from 'common/providers/analytics/adapters';
import { CartProvider } from 'common/providers/cart';
import StoreConfigProvider from 'common/providers/config';
import { GuestProvider } from 'common/providers/guest';
import { ChatMessageNotifier } from 'common/providers/message';
import PickupDeliveryProvider from 'common/providers/pickup-delivery';
import { PWAProvider } from 'common/providers/pwa';
import { useInitStorages } from 'common/providers/storages';
import UserProvider from 'common/providers/user';
import { PDPRouterProvider } from 'common/use-cases/use-pdp';

import { SignUpProvider } from 'domains/authorization/containers/sign-up/use-cases/use-sign-up';
import { ReferralProgramSubscriber } from 'domains/profile/containers/referral-program/containers/referral-program-subscriber';

import '@swe/shared/ui-kit/theme/provider/global.css';

const AppLayout = () => {
  const appSettings = useAppSettings();
  const { storagesFallback, storeConfig, reqHasAuthCookie, runtimeConfig } = appSettings;
  const { layoutProps } = usePageLayoutProps();

  const storages = useInitStorages(storagesFallback);

  const analyticsConfig: AnalyticsAdaptersConfig = useMemo(
    () => ({ posthog: runtimeConfig.posthogConfig }),
    [runtimeConfig.posthogConfig],
  );

  useMounted(() => {
    void firebase.init();
  });

  return (
    <StoreConfigProvider storeConfig={storeConfig}>
      <PostHogProvider client={posthog}>
        <PersistStateStorageProvider storages={storages}>
          <GoogleApiProvider apiKey={storeConfig.googleMapsApiKey}>
            <SnackbarProvider>
              <PDPRouterProvider>
                <GuestProvider>
                  <UserProvider hasAuthCookie={reqHasAuthCookie}>
                    <AnalyticsProvider config={analyticsConfig}>
                      <PWAProvider>
                        <CartProvider>
                          <SignUpProvider>
                            <PickupDeliveryProvider>
                              <ProductDetailsModal />
                              <ReferralProgramSubscriber />
                              <ChatMessageNotifier />
                              <MainLayout
                                preHeader={
                                  <>
                                    <SignUpNotifier />
                                    <ActionRequiredNotifier />
                                    <SupHeader />
                                  </>
                                }
                                header={<Header />}
                                subheader={<SubHeader />}
                                footer={<Footer />}
                                preFooter={<MedicalCardAssistance />}
                                {...layoutProps}
                              >
                                <ErrorBoundary level="fatal">
                                  <Outlet />
                                </ErrorBoundary>
                              </MainLayout>
                              <AgeRestriction />
                              <SignupIncentivization />
                            </PickupDeliveryProvider>
                          </SignUpProvider>
                        </CartProvider>
                      </PWAProvider>
                    </AnalyticsProvider>
                  </UserProvider>
                </GuestProvider>
              </PDPRouterProvider>
            </SnackbarProvider>
          </GoogleApiProvider>
        </PersistStateStorageProvider>
      </PostHogProvider>
    </StoreConfigProvider>
  );
};

export default AppLayout;
