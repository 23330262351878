import {
  init as initSentry,
  setTag,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
  httpClientIntegration,
  breadcrumbsIntegration,
  addBreadcrumb,
} from '@sentry/react';

import {
  DEFAULT_QUERY_CONFIG,
  hydrateQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@swe/shared/network/transport/query';

import { defaultLanguages, defaultLocale, IntlCore } from '@swe/shared/tools/intl/core/i18n';
import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';

import { ErrorInfo, StrictMode, useEffect } from 'react';

import { createRoot, hydrateRoot, RootOptions } from 'react-dom/client';
import {
  createBrowserRouter as _createBrowserRouter,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';

import { AppSettingsProvider, getAppSettings } from './app-settings';

import { createRoutesDef } from '@swe/shop-ui/app/router';
import { RootErrorPage } from 'app/error-page';
import logger from 'app/utils/logger';

const API_REGEXP = /\/_api\//;

void (async function hydrate() {
  const wasReactShellError = window.__sw_rhe ?? false;
  const appSettings = getAppSettings();
  const { runtimeConfig, storeConfig } = appSettings;
  let createBrowserRouter = _createBrowserRouter;

  if (runtimeConfig.sentry.enabled) {
    // https://github.com/getsentry/sentry-javascript/issues/14368
    let breadcrumbsIntegrationWasBroken = false;
    const bi = breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    });
    const originalBISetup = bi.setup?.bind(bi);
    bi.setup = (...args) => {
      try {
        return originalBISetup?.(...args);
      } catch (err) {
        breadcrumbsIntegrationWasBroken = true;
        return undefined;
      }
    };

    initSentry({
      ...runtimeConfig.sentry,
      allowUrls: [
        document.location.origin,
        ...(appSettings.themeBuild.assetPrefix ? [appSettings.themeBuild.assetPrefix] : []),
      ],
      environment: runtimeConfig.env,
      beforeSendSpan: (span) => {
        if (span.op === 'http.client' && API_REGEXP.test(span.description ?? '')) {
          // eslint-disable-next-line no-param-reassign
          span.description = `${span.data?.['http.method']} https://shop-api/${span.description?.split(API_REGEXP)[1]}`;
        }
        return span;
      },
      /*
      beforeSend: (event, hint) => {
        console.log(event, hint);
        return event;
      },
*/
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          stripBasename: true,
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration(),
        httpClientIntegration({
          failedRequestTargets: [API_REGEXP],
        }),
        bi,
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', API_REGEXP],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    let storeHost = document.location.host;
    try {
      storeHost = new URL(storeConfig.url).host;
    } catch {
      /* empty */
    }
    setTag('_sw.store.host', storeHost);
    setTag('_sw.store.id', storeConfig.id);
    setTag('_sw.store.is_reverse_proxy', !!storeConfig.externalApiHost);
    setTag('_sw.ssr.shell_error', wasReactShellError);
    setTag('_sw.sentry.breadcrumbsIntegrationWasBroken', breadcrumbsIntegrationWasBroken);
    setTag('_sw.navigator.isOnline', navigator.onLine);
    window.addEventListener('offline', () => {
      setTag('_sw.navigator.isOnline', false);
      addBreadcrumb({ message: 'Navigator goes offline' });
    });

    window.addEventListener('online', () => {
      setTag('_sw.navigator.isOnline', true);
      addBreadcrumb({ message: 'Navigator goes online' });
    });

    createBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
  }

  window.__sw_externalApiBasePath = appSettings.storeConfig.externalApiBasePath ?? undefined;
  window.__sw_externalApiHost = appSettings.storeConfig.externalApiHost ?? undefined;

  const theme = (await import(/* @vite-ignore */ appSettings.themeBuild.jsUrl)).default;
  if (!window.__sw_tt) {
    window.__sw_tt = {};
  }
  Object.assign(window.__sw_tt, theme);

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: DEFAULT_QUERY_CONFIG.dedupingInterval, gcTime: 20 * 60 * 1000 } },
  });
  if (window.__sw_qc) {
    hydrateQueryClient(queryClient, window.__sw_qc);
  }
  const routesDef = createRoutesDef(
    { isTruncatedShop: !!storeConfig.isTruncatedShop },
    {
      storeConfig,
      queryClient,
      intl: new IntlCore({
        language: defaultLocale,
        languages: defaultLanguages,
        dictionary: { [defaultLocale]: storeConfig.translations },
      }),
    },
  );

  const lazyMatches = matchRoutes(routesDef, window.location, storeConfig.routeName)?.filter((m) => m.route.lazy);
  if (lazyMatches && lazyMatches.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy!();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }

  const router = createBrowserRouter(routesDef, {
    basename: storeConfig.routeName,
    hydrationData: window.__sw_rd,
  });

  const rootOptions: RootOptions = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    onUncaughtError: (err, errInfo) =>
      logger.reactError(err, errInfo, { level: 'fatal', debugString: 'onUncaughtError' }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    onRecoverableError: (err: unknown, info: ErrorInfo) => {
      logger.reactError(err, info, { level: 'warning', debugString: 'onRecoverableError' });
    },
  };
  const app = (
    <StrictMode>
      <ErrorBoundary
        onError={logger.reactError}
        level="fatal"
        fallback={<RootErrorPage />}
      >
        <AppSettingsProvider value={appSettings}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </AppSettingsProvider>
      </ErrorBoundary>
    </StrictMode>
  );

  if (wasReactShellError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const root = createRoot(document, rootOptions);
    root.render(app);
  } else {
    hydrateRoot(document, app, rootOptions);
  }
})().catch((err) => {
  logger.error(err, { level: 'fatal' });
  throw err;
});
