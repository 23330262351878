import { RoutePath } from '@swe/shared/providers/router/constants';
import { Theme } from '@swe/shared/ui-kit/theme/provider/themes';

import { jsonParseSafe } from '@swe/shared/utils/object';

import { ShopRoute, Routes } from 'common/router/constants';
import { PaymentMethod } from 'entities/common/orders';
import { ProductSaleType } from 'entities/product/product';
import { FullShopFulfillmentType, ShopFulfillmentType } from 'entities/shop/info';

type ExternalNavLink = {
  label: string;
  href: AbsoluteURL;
};

type ExternalNavItem = {
  label: string;
  children: ExternalNavLink[];
};

export type ExternalNav = (ExternalNavItem | ExternalNavLink)[];

export enum OAuthProvider {
  Facebook = 'Facebook',
  Apple = 'Apple',
  Google = 'Google',
  Yahoo = 'Yahoo',
}

export enum SocialMedia {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Youtube = 'Youtube',
  Linkedin = 'Linkedin',
}

export type SocialMediaLink = {
  type: SocialMedia;
  href: AbsoluteURL;
};

export type OAuthConfig =
  | {
      enabled: false;
      id: undefined;
    }
  | { enabled: true; id: EntityID<string> };

export type OAuthConfigStore = Record<OAuthProvider, OAuthConfig>;

type ShopConfigOAuthDTO = {
  isAppleAuthenticationEnabled: boolean;
  isFacebookAuthenticationEnabled: boolean;
  isGoogleAuthenticationEnabled: boolean;
  isYahooAuthenticationEnabled: boolean;
  appleClientId?: EntityID<string>;
  facebookClientId?: EntityID<string>;
  googleClientId?: EntityID<string>;
  yahooClientId?: EntityID<string>;
};

export type Policy = {
  title: string;
  href: ShopRoute | AbsoluteURL;
  showAtRegistration?: boolean;
  content?: string;
  key?: string | number;
};

export type PWAScreenshot = {
  src: string;
  sizes: `${number}x${number}`;
  type: `image/${'gif' | 'png' | 'jpg' | 'jpeg'}`;
  form_factor: 'narrow' | 'wide';
  label: string;
};

type MaybeDisabled<T extends Record<string, any>, EnabledKey extends string> =
  | ({
      [key in Exclude<keyof T, EnabledKey>]: T[key];
    } & { [key in EnabledKey]: true })
  | ({
      [key in Exclude<keyof T, EnabledKey>]?: undefined;
    } & { [key in EnabledKey]: false });

export type GTMConfig = {
  gaKey?: string; // Google Analytics key. Will be inited if set
  gtmAuth?: string; // Google Tag Manager auth key
  gtmPreview?: string; // Google Tag Manager preview key
};

export type PWAConfig = MaybeDisabled<
  {
    pwaTitle: string;
    pwaShortName: string;
    pwaDescription: string;
    pwaScreenshots: string;
    pwaScope?: string;
    pwaStartUrl?: string;
  },
  'pwaEnabled'
>;

type PageConfig = {
  canonicalUrl?: AbsoluteURL;
};

export type PageManagerConfig = Partial<Record<keyof typeof Routes, PageConfig>>;

// HARDCODES
type ShopConfigHardcode = Partial<{
  title: string; // used in html head title
  theme: Theme; // string name of design theme
  logoUrl: AbsoluteURL; // custom link from logo if available (in such case separate menu item "Home" is rendered)
  privacyPolicy: HTMLString; // HTML string of policy. If its empty all links are hidden
  termsOfService: HTMLString; // HTML string of terms of service If its empty all links are hidden
  isFooterEnabled: boolean; // Show/hide footer
  isCancerWarningEnabled: boolean; // Show message about cancer in footer
  copyrightOwner: string; // in footer
  licenseCode: string; // in footer
  isResidenceToggleEnabled: boolean; // Show toggle on cart page
  showShopAllCategory: boolean; // Show "all category" item in categories carousel
  shopAllCategoryImg: AbsoluteURL; // Custom image url for "all category" item
  notificationAgreement: string; // Text of notificationAgreement during registration. If defined we show  checkbox on the phone setting step
  notificationAgreementDefaultValue: boolean; // default value for previous checkbox
  isCheckoutConsentsEnabled: boolean; // Customer has checked out the order and navigated to the page for order placement
  googleMapsApiKey: string; // googleMapsApiKey
  aeroPayEnv: 'staging' | 'production';
  aeroPayLocationId: string; // AeroPayPay locationId
  aeroPayMerchantId: string; // AeroPayPay merchantId
  areFulfilmentSettingsEnabled: boolean;
  isPdpModalMobileEnabled: boolean;
  isPdpModalTabletEnabled: boolean;
  isPdpModalDesktopEnabled: boolean;
  isDateOfBirthRequired: boolean;
  isDateOfBirthNeeded: boolean;
  medicalCardAssistanceLink: AbsoluteURL;
  externalNav: ExternalNav;
  externalNavFooter: ExternalNav;
  socialMediaLinks: SocialMediaLink[];
  medChatApiKey: string;
  policies: Policy[];
  slogan: string;
  footerDisclaimer: string;
  externalApiHost: AbsoluteURL;
  externalApiBasePath: string;
  baseApiUrl: string;
  guestNotificationsAgreement: string;
  translations: Translations;
  canSkipPhoneVerification: boolean;
  isPushNotificationsEnabled: boolean;
  appleAppId: string;
  dangerouslyHidePaymentMethods: Partial<Record<FullShopFulfillmentType, PaymentMethod[]>>;
  homePageDealsCarouselIsEnabled: boolean;
  homePageDealsCarouselMaxCount: number;
  // https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
  faviconIcoSrc: AbsoluteURL; // https://sweedpos.s3.amazonaws.com/store/production/1692188341_0702a452-a396-4ef6-8d25-aecafc549944.ico
  iconSvgSrc: AbsoluteURL; // https://sweedpos.s3.amazonaws.com/store/production/1692188818_fda21fac-19df-4e68-9635-05bb788aec47.svg
  icon16x16PngSrc: AbsoluteURL;
  icon32x32PngSrc: AbsoluteURL;
  appleTouchIcon180x180PngSrc: AbsoluteURL; // https://sweedpos.s3.amazonaws.com/store/production/1692188582_8f00df3a-b7e7-47fb-8c93-13ca47cd2076.png
  safariMaskIconSvgSrc: AbsoluteURL;
  androidIcon192x192PngSrc: AbsoluteURL; // https://sweedpos.s3.amazonaws.com/store/production/1692188647_864afe24-a40a-4929-800c-a56a13c95ad7.png
  androidIcon512x512PngSrc: AbsoluteURL; // https://sweedpos.s3.amazonaws.com/store/production/1692188725_8ce3eb7a-aeab-4667-8158-4ec96931237f.png
  msAppLogo150x150PngSrc: AbsoluteURL;
  isSignupPopupEnabled: boolean;
  isSignupNotifierEnabled: boolean;
  signupNotifierFaqLink?: string;
  signupPopupTitle: string;
  signupPopupDescription: string;
  signupPopupDelay: number;
  signupPopupTimeout: number;
  pageManagerConfig: PageManagerConfig;
  isTruncatedShop: boolean; // truncated version of the shop
  saleTypeBasedRoutingEnabled: boolean;
  dealerName: string; // Hardcoded dealer name (temporary)
  usageDisclaimer: string;
  signupPageTitle: string;
  separateTitlePopupHeaderAndSubHeader: boolean;
}> &
  GTMConfig &
  PWAConfig;

export const SHOP_CONFIG_DEFAULTS = {
  theme: 'default',
  isFooterEnabled: true,
  aeroPayEnv: 'staging',
  aeroPayLocationId: '2550',
  aeroPayMerchantId: '1184',
  googleMapsApiKey: 'AIzaSyA9rtNkpYzJAELPbjIIEMGhdC_5ISmzU68',
  stickyLoginBarEnabled: true,
  saleTypeBasedRoutingEnabled: false,
};

/* const SanctuaryConfig: ShopConfigHardcode = {
  title: 'Sanctuary Cannabis Dispensary',
  copyrightOwner: 'Sanctuary',
  theme: 'sanctuary',
  aeroPayEnv: 'production',

  notificationAgreement:
    'I consent to receive informational and promotional messages at the mobile phone number and/or email address I have provided. If somebody reaches out to you, that implies consent to respond to the individual with relevant information. This is the simplest form of consent; they text you first! But just because they text you a question, ask for a meeting, or are simply looking for more information; doesn’t mean that you can text them whenever you’d like. In order to send more information after the initial conversation, you must obtain express consent. Check out the next section to learn more about that.',
  notificationAgreementDefaultValue: true,

  faviconIcoSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856013_e860a461-5390-4393-ad7f-711e261a0488.ico',
  iconSvgSrc: 'https://sweedpos.s3.amazonaws.com/store/production/1698856098_428e7fe3-cea2-4692-b721-f74229f9e4eb.svg',
  icon16x16PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856138_428e7fe3-cea2-4692-b721-f74229f9e4eb.png',
  icon32x32PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856177_9c63be34-9ed3-43e6-bc4e-2b7d195e3ba0.png',
  appleTouchIcon180x180PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856235_5b6a1ade-16be-4acc-b1d2-f2b0b99074c9.png',
  safariMaskIconSvgSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856098_428e7fe3-cea2-4692-b721-f74229f9e4eb.svg',
  androidIcon192x192PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856291_34d54190-5605-4d9e-8a0a-ca18f2446ff8.png',
  androidIcon512x512PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856340_076ee0af-3d06-4956-8825-cba71f17e543.png',
  msAppLogo150x150PngSrc:
    'https://sweedpos.s3.amazonaws.com/store/production/1698856378_32027ed5-f3f6-4ccd-9097-77ca28241e08.png',
}; */

export interface Translations {
  [key: string]: Translations | string;
}

export type StoreAvailableSaleType = ProductSaleType.MEDICAL | ProductSaleType.RECREATIONAL;

type ShopConfigCommon = ShopConfigHardcode & {
  id: EntityID<number>;
  parentId: EntityID;
  logo: AbsoluteURL;
  url: AbsoluteURL;
  medicalMenuEnabled: boolean;
  routeName?: RoutePath;
  originalRouteName?: RoutePath;
  timezoneShiftInHrs: number;
  timezoneName: string;
  isPatientIdSignUpRequired: boolean;
  isReferralProgramEnabled: boolean;
  isReferralBannerEnabled: boolean;
  skipPhoneVerification: boolean;
  isCourierSnapshotAvailable?: boolean;
  availableFulfillmentTypes: ShopFulfillmentType[];
  availableSaleTypes: StoreAvailableSaleType[];
  storesCount: number;
  guestSettings: {
    isDobRequired: boolean;
    isEmailRequired: boolean;
    isFirstNameRequired: boolean;
    isGuestEnabled: boolean;
    isLastNameRequired: boolean;
    isPhoneRequired: boolean;
    isMedicalGuest: boolean;
  } | null;
  isOnlineOrderingDisabled: boolean;
  isAgeCheckRequired: boolean;
  minimalCustomerAge: number;
  hideGuestConsent?: boolean;
  productCardBadgePlacement?: 'over-the-image' | 'under-the-image';
  stickyLoginBarEnabled?: boolean;
  hideLoyaltyCurrency?: boolean;
};

export type ShopConfigDTO = ShopConfigOAuthDTO & ShopConfigCommon;

export type ShopConfig = Omit<ShopConfigCommon, 'termsOfService' | 'privacyPolicy' | 'policies'> &
  Required<Pick<ShopConfigCommon, keyof typeof SHOP_CONFIG_DEFAULTS>> & {
    oAuth: OAuthConfigStore;
  };

const KEYS_TO_PARSE: (keyof ShopConfig)[] = [
  'externalNav',
  'externalNavFooter',
  'socialMediaLinks',
  'dangerouslyHidePaymentMethods',
  'pageManagerConfig',
  'translations',
];

export const mapShopConfigDTOToEntity = ({
  isAppleAuthenticationEnabled,
  isFacebookAuthenticationEnabled,
  isGoogleAuthenticationEnabled,
  isYahooAuthenticationEnabled,
  appleClientId,
  facebookClientId,
  googleClientId,
  yahooClientId,

  // TODO: hardcoded cleaning of hydration data, remove after store info and config optimization
  privacyPolicy,
  termsOfService,
  policies,

  ...dto
}: ShopConfigDTO): ShopConfig => {
  const result = <ShopConfig>{
    ...dto,
    oAuth: {
      [OAuthProvider.Facebook]: {
        enabled: isFacebookAuthenticationEnabled,
        id: facebookClientId,
      },
      [OAuthProvider.Google]: {
        enabled: isGoogleAuthenticationEnabled,
        id: googleClientId,
      },
      [OAuthProvider.Apple]: {
        enabled: isAppleAuthenticationEnabled,
        id: appleClientId,
      },
      [OAuthProvider.Yahoo]: {
        enabled: isYahooAuthenticationEnabled,
        id: yahooClientId,
      },
    },
  };

  KEYS_TO_PARSE.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result[key] = jsonParseSafe(result[key]);
  });

  return result;
};
