import SharedProvider from '@swe/shared/provider';
import { CommonQueryParams } from '@swe/shared/providers/router/constants';
import { useIsPWA } from '@swe/shared/tools/is-pwa';
import { Error500 } from '@swe/shared/ui-kit/components/error-500';
import { isSSR } from '@swe/shared/utils/environment';

import { useMemo } from 'react';
import { Outlet, RouteObject, ScrollRestoration, useParams } from 'react-router-dom';

import { useAppSettings } from 'app/app-settings';
import { useClientWidth } from 'app/hooks';
import Document from 'app/layout/document';
import { getThemeUtils } from 'app/theme';
import { parseUA } from 'app/utils';

import logger from 'app/utils/logger';
import RouterProvider from 'common/router';

const ErrorFallback = ({ error }: { error: any }) => <Error500 error={error} />;

type CoreProviderProps = { pages: RouteObject[] };

const CoreLayout = ({ pages }: CoreProviderProps) => {
  const appSettings = useAppSettings();
  const { reqHasAuthCookie, themeBuild, storeConfig, clientWidthFallback, reqUserAgent } = appSettings;
  const revalidateOnInit = reqHasAuthCookie ?? true;
  const themeUtils = getThemeUtils(themeBuild);
  const { isOpera, isIOS, isAndroid } = parseUA(isSSR ? reqUserAgent : window.navigator.userAgent);
  const clientWidth = useClientWidth(clientWidthFallback);
  const isPWA = useIsPWA();
  const params = useParams();
  const saleTypeQueryParam = params[CommonQueryParams.SaleType];
  const defaultQuery = useMemo(() => {
    return saleTypeQueryParam ? { [CommonQueryParams.SaleType]: saleTypeQueryParam } : {};
  }, [saleTypeQueryParam]);

  return (
    <RouterProvider
      routes={pages}
      defaultQuery={defaultQuery}
    >
      <Document appSettings={appSettings}>
        <SharedProvider
          {...themeUtils}
          errorFallback={ErrorFallback}
          onError={logger.reactError}
          revalidateOnInit={revalidateOnInit}
          externalApiBasePath={storeConfig.externalApiBasePath}
          externalApiHost={storeConfig.externalApiHost}
          clientWidth={clientWidth}
          isIOS={isIOS}
          isAndroid={isAndroid}
          isPWA={isPWA}
          isOpera={isOpera}
          messages={storeConfig.translations}
        >
          <Outlet />
        </SharedProvider>
        <ScrollRestoration />
      </Document>
    </RouterProvider>
  );
};

export default CoreLayout;
