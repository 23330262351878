import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type Portals = 'common' | 'fixedFooter' | 'fixedFooterTop';

const DEFAULT_PORTAL: Portals = 'common';

type Targets = Partial<Record<Portals, Element | null>>;

type PortalContext = {
  registerTarget(name: Portals, node: Element | null): void;
  targets: Targets;
};

const portalContext = createContext<PortalContext>(null!);

const PortalProvider = ({ children }: ComponentHasChildren) => {
  const [targets, setTargets] = useState<Partial<Record<Portals, Element | null>>>({});

  const registerTarget = useCallback((name: Portals, node: Element) => {
    setTargets((targets) => {
      if (targets[name] === node) {
        return targets;
      }
      return {
        ...targets,
        [name]: node,
      };
    });
  }, []);

  return (
    <portalContext.Provider
      value={useMemo(
        () => ({
          registerTarget,
          targets,
        }),
        [registerTarget, targets],
      )}
    >
      {children}
    </portalContext.Provider>
  );
};

type PortalTargetProps = {
  name?: Portals;
  level?: number;
};

const PortalTarget = ({ name = DEFAULT_PORTAL, level }: PortalTargetProps) => {
  const { registerTarget } = useContext(portalContext);
  const setTargetNode = useCallback(
    (node: HTMLDivElement | null) => {
      registerTarget(name, node);
    },
    [name, registerTarget],
  );

  return (
    <div
      style={{ position: 'relative', zIndex: level ?? 'auto' }}
      ref={setTargetNode}
      data-portal={name}
    />
  );
};

type PortalProps = {
  name?: Portals;
} & ComponentHasChildren;

const Portal = ({ children, name = DEFAULT_PORTAL }: PortalProps) => {
  const { targets } = useContext(portalContext);

  const node = targets[name];
  return node ? createPortal(children, node) : null;
};

const PORTAL_TARGET = {
  Common: () => <PortalTarget level={1} />,
  FixedFooter: () => <PortalTarget name="fixedFooter" />,
  FixedFooterTop: () => <PortalTarget name="fixedFooterTop" />,
};

export { PORTAL_TARGET, Portal, PortalProvider };
