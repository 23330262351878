// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as sentryNode from '@sentry/node?server';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as sentryReact from '@sentry/react?client';
import { ErrorInfo } from 'react';

type ErrorLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

type ErrorHint = {
  level?: ErrorLevel;
  reactInfo?: ErrorInfo;
  debugString?: string;
};

const isSSR = import.meta.env.SSR;
const captureException = isSSR ? sentryNode.captureException : sentryReact.captureException;
const setUser = isSSR ? sentryNode.setUser : sentryReact.setUser;

const LEVEL_TO_CONSOLE_METHOD: Record<ErrorLevel, 'error' | 'warn' | 'log' | 'info' | 'debug'> = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  log: 'log',
  info: 'info',
  debug: 'debug',
};

const logger = {
  error(error: any, { reactInfo, debugString, ...hint }: ErrorHint = {}) {
    const finalHint = {
      ...hint,
      ...(reactInfo?.componentStack
        ? {
            captureContext: {
              contexts: { react: { componentStack: reactInfo?.componentStack } },
            },
          }
        : {}),
    };
    const id = captureException(error, finalHint);
    const level = hint.level ?? 'error';
    // eslint-disable-next-line
    console[LEVEL_TO_CONSOLE_METHOD[level] ?? 'log'](
      `[${level}]${debugString ? ` [${debugString}]` : ''}`,
      error,
      finalHint,
    );
    return id;
  },
  reactError(error: unknown, reactInfo?: ErrorInfo, hint?: ErrorHint) {
    this.error(error, { ...hint, reactInfo });
  },
  setUser(user: { id: EntityID } | null) {
    setUser({
      ...(user ?? {}),
      ip_address: '{{auto}}',
    });
  },
};

logger.reactError = logger.reactError.bind(logger);

export default logger;
