import { useMounted } from '@swe/shared/hooks/use-mounted';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import { useMemo } from 'react';

import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { CarouselProducts, CarouselProductsProps } from 'domains/shop/components/carousel-products';
import { Skeleton } from 'domains/shop/components/carousel-products/skeleton';

const _CarouselProducts = ({ onViewAll, ...props }: CarouselProductsProps) => {
  const { pushEvent } = useAnalytics();

  const eventPayload = useMemo(() => {
    return {
      carouselId: props.id,
      carouselName: props.name,
      carouselOrigin: props.origin,
      ...(props.ruleType && {
        carouselType: props.ruleType,
      }),
    };
  }, [props.id, props.name, props.origin, props.ruleType]);

  useMounted(() => {
    pushEvent(AEventType.CAROUSEL_IMPRESSION, {
      ...eventPayload,
      topProducts: props.products.map((p) => p.id),
    });
  });

  const onViewAllHandler = useMemo(() => {
    if (!onViewAll) return;
    return () => {
      pushEvent(AEventType.CAROUSEL_VIEW, eventPayload);
      onViewAll();
    };
  }, [eventPayload, onViewAll, pushEvent]);

  return (
    <CarouselProducts
      {...props}
      onViewAll={onViewAllHandler}
    />
  );
};

const _CarouselProductsSkeletonized = withSkeleton(_CarouselProducts, Skeleton, ['products']);

export { _CarouselProducts as CarouselProducts, _CarouselProductsSkeletonized as CarouselProductsSkeletonized };
